import { useMutation, useQuery } from '@apollo/client';
import { useEffect } from 'react';
import { GetBaseUserResponse } from '@/api/types/user.types';
import { GET_BASE_USER } from '@/api/queries/user.queries';
import { CREATE_BOOKMARK } from '@/api/mutations/bookmarks.mutations';
import { useBookmarksStore } from '@/store/useBookmarksStore/useBookmarksStore';
import {
  getStoredBookmarks,
  setStoredBookmarks,
} from '@/store/useBookmarksStore/useBookmarksStore.helpers';
import { AuthStatus, useAuthStore } from '@/store/useAuthStore';

// TODO refactor clearing bookmarks after logout will be ready
let isBookmarksAdded = false;

export function useAuth() {
  const [setUserBookmarks] = useBookmarksStore(state => [state.setBookmarks]);
  const [setIsAuthLoaded, setUserId, setAuthStatus, setIsAuthenticated] =
    useAuthStore(state => [
      state.setIsAuthLoaded,
      state.setUserId,
      state.setAuthStatus,
      state.setIsAuthenticated,
    ]);
  const [createBookmark] = useMutation(CREATE_BOOKMARK);

  const { observable, refetch: getBaseUser } = useQuery<GetBaseUserResponse>(
    GET_BASE_USER,
    {
      skip: true,
      fetchPolicy: 'network-only',
    }
  );

  function handleAnonymous() {
    if (isBookmarksAdded) {
      setStoredBookmarks([]);
      setUserBookmarks([]);
      isBookmarksAdded = false;
    }
    setIsAuthenticated(false);
    setAuthStatus(AuthStatus.Unauthenticated);
  }

  function handleBookmarCreation() {
    const storedBookmarks = getStoredBookmarks().reverse();
    if (!isBookmarksAdded && storedBookmarks.length) {
      createBookmark({
        variables: { adIds: storedBookmarks.map(b => b.id) },
      });

      isBookmarksAdded = true;
    }
  }

  function handleAuthenticated(id: string) {
    setUserId(id);
    handleBookmarCreation();
    setIsAuthenticated(true);
    setAuthStatus(AuthStatus.Authenticated);
  }

  function handleError() {
    setIsAuthLoaded(true);
    setUserId(null);
    setIsAuthenticated(false);
    setAuthStatus(AuthStatus.Unauthenticated);
  }
  useEffect(() => {
    async function init() {
      try {
        const { data } = await observable.result();
        setIsAuthLoaded(true);
        setUserId(null);

        if (data?.currentUser) {
          handleAuthenticated(data.currentUser.id);
        }

        if (data?.currentUser === null) {
          handleAnonymous();
        }
      } catch (e) {
        handleError();
      }
    }
    init();
  }, [observable]);

  return getBaseUser;
}
