import { gql } from '@apollo/client';
import { AD_BASE_FIELDS } from '@/api/queries/ads.queries';
import { MAX_PAYMENT_CARDS } from '@/components/Profile/Cards/Cards.constants';

export const GET_BASE_USER = gql`
  query GetBaseUser {
    currentUser {
      id
    }
  }
`;

export const GET_USER_BOOKMARKS = gql`
  ${AD_BASE_FIELDS}
  query GetUserBookmarks($first: Int, $after: String) {
    currentUser {
      id
      bookmarks(first: $first, after: $after) {
        nodes {
          ...AdBaseFields
        }
        totalCount
        pageInfo {
          endCursor
          hasNextPage
        }
      }
    }
  }
`;

export const GET_USER = gql`
  query GetUser {
    currentUser {
      id
      email
      name
      authenticationPhone
      walletBalance
      cards(first: ${MAX_PAYMENT_CARDS}) {
        nodes {
          id
          legacyResourceId
          name
          pan
          paymentSystem
        }
      }
      walletDiscount
      shopPaidAdsBalance
      isShop
      isActiveShop
      isBanned
    }
  }
`;
