import { ComponentType, FC, useContext } from 'react';
import { BridgeContext, BridgeAPIContext } from './BridgeProvider';
import { BridgeAPIProps, WithBridgeStateProps } from '@/bridge/types';

export const useBridgeState = () => {
  return useContext(BridgeContext);
};

export const useBridgeAPI = () => {
  return useContext(BridgeAPIContext);
};

// Utility type for extracting and merging props
type Without<T, K> = Pick<T, Exclude<keyof T, keyof K>>;

export const withBridgeState = <P extends WithBridgeStateProps>(
  Component: ComponentType<P>
) => {
  // Component that wraps the original component
  const BridgeStateWrapper: FC<Without<P, WithBridgeStateProps>> = props => {
    const state = useBridgeState();

    return <Component {...(props as P)} bridgeState={state} />;
  };

  // Assign a display name for debugging purposes
  BridgeStateWrapper.displayName = `withBridgeState(${
    Component.displayName || 'BridgeStateWrapper'
  })`;

  return BridgeStateWrapper;
};

export const withBridgeAPI = <P extends object>(
  Component: ComponentType<P & BridgeAPIProps>
): ComponentType<P> => {
  const BridgeAPIWrapper: FC<P> = props => {
    const state = useBridgeAPI();

    return <Component {...props} bridgeAPI={state} />;
  };

  // Assign a display name for debugging purposes
  BridgeAPIWrapper.displayName = `withBridgeAPI(${
    Component.displayName || 'BridgeAPIWrapper'
  })`;

  return BridgeAPIWrapper;
};
