import { useEffect } from 'react';

export const useBackNavigation = () => {
  useEffect(() => {
    const handlePopstate = () => {
      const currentUrl = window.location.href;
      const initialUrl = sessionStorage.getItem('initialUrl');
      if (currentUrl === initialUrl) {
        sessionStorage.removeItem('initialUrl');
      }
    };

    window.addEventListener('popstate', handlePopstate);

    return () => {
      window.removeEventListener('popstate', handlePopstate);
    };
  }, []);
};
