import { useEffect } from 'react';
import { useRouter } from 'next/router';
import { useMobileDetect } from '@/hooks/useMobileDetect';
import { ROUTES } from '@/constants/routes';

export function useBodyClasses() {
  const { isIos } = useMobileDetect();
  const router = useRouter();

  useEffect(() => {
    if (isIos) {
      document.body.classList.add('ios');
    }
    if (router.pathname === ROUTES.walletFinish) {
      document.body.classList.add('wallet');
    }
  }, []);
}
