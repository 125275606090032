import { createGlobalStyle } from 'styled-components';

export const GlobalStyles = createGlobalStyle`
  @font-face {
    font-family: 'Helvetica Neue';
    src: font-url('./fonts/HelveticaNeue.woff2') format('woff2');
    font-weight: 400;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Helvetica Neue';
    src: font-url('./fonts/HelveticaNeue-Medium.woff2') format('woff2');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Helvetica Neue';
    src: font-url('./fonts/HelveticaNeue-Bold.woff2') format('woff2');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
  }
  
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    text-size-adjust: none;
    -webkit-backface-visibility: hidden;
  }

  html {
      -webkit-text-size-adjust: 100%; 
  }
  
  body {
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    background-color: ${({ theme }) => theme.palette.secondary.background};
    color: ${({ theme }) => theme.palette.primary.labels};
    font-weight: 400;
    line-height: 1.2;
    min-height: 100vh;
    display: flex;
    -webkit-font-smoothing: antialiased;
    overflow-x: hidden;
      
    &.ios {
      overscroll-behavior: none;
    } 

    &.wallet {
      background-color: ${({ theme }) => theme.palette.primary.background};
    }

    &.dynamic-height {
      height: 100dvh;
      min-height: auto;
    }
  }
  
  #__next {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex: 1;
  }

  img {
    max-width: 100%;
    font-style: italic;
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:focus {
    transition: background-color 600000s 0s, color 600000s 0s;
  }

  input[type='search']::-webkit-search-decoration,
  input[type='search']::-webkit-search-cancel-button,
  input[type='search']::-webkit-search-results-button,
  input[type='search']::-webkit-search-results-decoration {
      display: none;
  }

  input[type=file] {
    display: none;
  }
`;
