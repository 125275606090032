import PubNub from 'pubnub';
import { CustomFieldsMap } from '@/utils/pubnub/pubnub.types';

export const formatMemberships = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: PubNub.ChannelMembershipObject<any, any>[]
) => {
  return data.reduce<{
    allChannelKeys: string[];
    customFieldsMap: CustomFieldsMap;
  }>(
    (acc, curr) => ({
      allChannelKeys: [...acc.allChannelKeys, curr.channel.id],
      customFieldsMap: {
        ...acc.customFieldsMap,
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
        [curr.channel.id]: curr.custom,
      },
    }),
    { allChannelKeys: [], customFieldsMap: {} }
  );
};
