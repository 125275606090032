import Router from 'next/router';
import {
  BRIDGE_NO_TOP_INSET_KEY,
  BRIDGE_NO_TOP_INSET_REGEXP,
} from '@/bridge/config';

export const noTopInsert = (pathname?: string) => {
  if (typeof window !== 'undefined') {
    const parsedUrl = pathname
      ? new URL(`${window.location.origin}${pathname}`)
      : new URL(`${window.location.href}`);

    const isSafeArea =
      parsedUrl.searchParams.get(BRIDGE_NO_TOP_INSET_KEY) === 'false';

    if (
      BRIDGE_NO_TOP_INSET_REGEXP.some((rx: RegExp) =>
        rx.test(parsedUrl.pathname)
      ) &&
      !isSafeArea
    ) {
      parsedUrl.searchParams.set(BRIDGE_NO_TOP_INSET_KEY, 'false');

      Router.replace(parsedUrl.toString());
    }
  }
};
