import { createGlobalStyle } from 'styled-components';

export const BannersStyles = createGlobalStyle`
    .lotriver-top-banner {
        z-index: 10;

        &--rows {
            margin-top: -8px;
        }

        &:empty {
            display: none;
        }

        a img {
            width: 100%;
            max-width: 480px !important;
            object-fit: cover;
        }
    }

    .lotriver-bottom-banner {
        display: none;
        width: 100%;
        margin: 0 auto;

        a img {
            width: 100%;
            max-width: 480px !important;
            object-fit: cover;
        }

        @media (max-width: 740px) {
            display: block;
        }

        &--in-list {
            width: 100%;
        }

        &.content-banner {
            overflow: hidden;

            @media (min-width: 480px) {
                display: none;
            }
        }
    }

    .google-buds-banner-scammer, .google-buds-bottom-banner, .google-buds-top-banner {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        max-width: 320px;
        max-height: 100px;
        margin: 0 auto;
    }
`;
