import { MessageActionEvent } from 'pubnub';
import { READ_MESSAGE_ACTION, TIMETOKEN_DIVIDER } from './pubnub.constants';
import { PubNubStore } from '@/store/usePubNubStore';

export const onMessageAction = (
  { channel, data: { value, messageTimetoken } }: MessageActionEvent,
  partialUpdateLastMessagesMap: PubNubStore['partialUpdateLastMessagesMap']
) => {
  if (value === READ_MESSAGE_ACTION) {
    partialUpdateLastMessagesMap(channel, {
      lastReadTimetoken: messageTimetoken,
      timetoken: messageTimetoken,
      marker: Math.trunc(+messageTimetoken / TIMETOKEN_DIVIDER).toString(),
      isReadMessage: true,
    });
  }
};
