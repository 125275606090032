/* eslint-disable @typescript-eslint/no-shadow */
/* eslint-disable @typescript-eslint/no-namespace */

import * as PaymentTypes from './payment';

type Insets = { top: number; bottom: number; left: number; right: number };

export enum EventName {
  Connecting = 'connecting',
  SetConfig = 'setConfig',
  Handshake = 'handshake',
  OpenUrl = 'openUrl',
  OpenPayment = 'openPayment',
  PaymentStatus = 'paymentStatus',
  CloseWebview = 'closeWebview',
}

export type ValueOf<T> = T[keyof T];

export namespace Response {
  export type Params = {
    [EventName.Connecting]: { origin: string };
    [EventName.SetConfig]: { origin: string; originBrowserList: string[] };
    [EventName.Handshake]: { origin: string };
    [EventName.OpenUrl]: { origin: string; url: string };
    [EventName.OpenPayment]: { origin: string; url: string };
    [EventName.CloseWebview]: { origin: string };
  };

  export type EventMessage<E extends keyof Params> = {
    eventName: E;
    params: Params[E];
  };

  export type Events = {
    [EventName.Connecting]: EventMessage<EventName.Connecting>;
    [EventName.SetConfig]: EventMessage<EventName.SetConfig>;
    [EventName.Handshake]: EventMessage<EventName.Handshake>;
    [EventName.CloseWebview]: EventMessage<EventName.CloseWebview>;
    [EventName.OpenUrl]: EventMessage<EventName.OpenUrl>;
    [EventName.OpenPayment]: EventMessage<EventName.OpenPayment>;
  };

  export type Event = ValueOf<Events>;
}

export namespace Request {
  export type Params = {
    [EventName.Connecting]: { origin: string };
    [EventName.SetConfig]: {
      origin: string;
      bridgeVersion: number;
      insets: Insets;
    };
    [EventName.Handshake]: { origin: string };
    [EventName.PaymentStatus]: {
      origin: string;
    } & PaymentTypes.PaymentStatusData;
  };

  export type EventMessage<E extends keyof Params> = {
    eventName: E;
    params: Params[E];
  };

  export type Events = {
    [EventName.Connecting]: EventMessage<EventName.Connecting>;
    [EventName.SetConfig]: EventMessage<EventName.SetConfig>;
    [EventName.Handshake]: EventMessage<EventName.Handshake>;
    [EventName.PaymentStatus]: EventMessage<EventName.PaymentStatus>;
    [EventName.OpenPayment]: EventMessage<EventName.PaymentStatus>;
  };

  export type OneOfParams = ValueOf<Params>;
  export type Event = ValueOf<Events>;
}
