import { create } from 'zustand';
import { persist } from 'zustand/middleware';
import { User } from '@/api/types/user.types';
import { STORAGE_KEYS } from '@/constants/storageKeys';

export enum AuthStatus {
  Idle,
  Error,
  Authenticated,
  Unauthenticated,
}

interface AuthStore {
  isAuthLoaded: boolean;
  setIsAuthLoaded: (value: boolean) => void;
  isAuthenticated: boolean;
  setIsAuthenticated: (value: boolean) => void;
  authStatus: AuthStatus;
  setAuthStatus: (value: AuthStatus) => void;
  userId: User['id'] | null;
  setUserId: (value: User['id'] | null) => void;
  csrfToken: string | null;
  setCsrfToken: (value: string | null) => void;
}

export const useAuthStore = create(
  persist<AuthStore>(
    set => ({
      isAuthLoaded: false,
      setIsAuthLoaded: value => set({ isAuthLoaded: value }),
      isAuthenticated: false,
      setIsAuthenticated: value => set({ isAuthenticated: value }),
      authStatus: AuthStatus.Idle,
      setAuthStatus: value => set({ authStatus: value }),
      userId: null,
      setUserId: value => set({ userId: value }),
      csrfToken: null,
      setCsrfToken: value => set({ csrfToken: value }),
    }),
    { name: STORAGE_KEYS.AUTH_STORAGE }
  )
);
