/* eslint-disable @typescript-eslint/no-namespace */
import { PaymentStatus } from '@/api/types/payments.types';

export enum Gateways {
  Azericard = 'azericard',
}

export namespace GenericPaymentStatusData {
  export type Success = {
    status: PaymentStatus.Success;
    amount: string;
    updated_at: string;
    payment_id: string;
    message: string;
    target_id: string;
    service_name: string;
    service_id: string;
    is_wallet: string;
    payment_source: string;
  };

  export type Failure = {
    status: PaymentStatus.Failure;
  };
}

export namespace AzericardPaymentStatusData {
  export type Success = {
    amount: string;
    currency: string;
    merchant_name: string;
    order_id: string;
    rrn: string;
    status: PaymentStatus.Success;
    gateway: Gateways.Azericard;
  };

  export type Fault = {
    reason: string;
    bank_response_code: string;
    status: PaymentStatus.Failure;
    gateway: Gateways.Azericard;
  };

  export type Duplicate = {
    amount: string;
    currency: string;
    order_id: string;
    rrn: string;
    merchant_name: string;
    status: PaymentStatus.Failure;
    gateway: Gateways.Azericard;
  };

  export type Decline = {
    rrn: string;
    bank_response_code: string;
    status: PaymentStatus.Failure;
    gateway: Gateways.Azericard;
  };
}

export type PaymentStatusData =
  | GenericPaymentStatusData.Success
  | GenericPaymentStatusData.Failure
  | AzericardPaymentStatusData.Success
  | AzericardPaymentStatusData.Fault
  | AzericardPaymentStatusData.Duplicate
  | AzericardPaymentStatusData.Decline;

export type PaymentSuccessStatusData =
  | GenericPaymentStatusData.Success
  | AzericardPaymentStatusData.Success;
